@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}
ul {
  list-style-type: none;
}
button {
  border: none;
}

body{
  font-family: 'Inter', sans-serif;

}

#root{
  min-height: 100vh;
  display:flex; 
  flex-direction:column; 
}